// Here you can add other styles
.tab-list {  border-bottom: 1px solid rgb(179, 178, 178);
    padding-left: 0;
    //background-color: #EFCDA8;
    //border: solid #607D8B;
    font-weight: 600;
    color:#607D8B;
    cursor: pointer;
  }

  .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    font-weight: 600;
    cursor: pointer;
  }

  .tab-list-active {
    background-color: #607D8B;
    color:white;
    font-weight: 600;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    cursor: pointer;
  }
  .guardar {
    background-color: #219C8F;
    color:WHITE;
    font-weight: 600;
    font-size: 12px;
    padding: 10px 10px;
    border-radius: 6px;
    margin: 10px 10px;
    border: solid #FFF;

  }
  .reset {
    background-color: #E91E63;
    color:WHITE;
    font-weight: 600;
    font-size: 12px;
    padding: 10px 10px;
    border-radius: 6px;
    margin: 10px 10px;
    border: solid #FFF;

  }
  .transparent {
    background-color: Transparent;
    color:#219C8F;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 5px;
    margin: 5px 5px;
  }
  .transparentedit {
    background-color: Transparent;
    color:#607D8B;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 5px;
    margin: 5px 5px;
  }
  .transparenteditdisabled {
    background-color: Transparent;
    color:#5a6266;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 5px;
    margin: 5px 5px;
  }
  .transparenteliminar {
    background-color: Transparent;
    color:#F44336;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 5px;
    margin: 5px 5px;
  }
  .transparenteliminardisabled{
    background-color: Transparent;
    color:#421f1d;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 5px;
    margin: 5px 5px;
  }
  .labelinfo {
    font-weight: 700;
    font-size: 16px;
    color:#3f3f3f;
  }
  .labelcampo{
    font-weight: 500;
    color:#607D8B;
  }
  .modaltitulo{
    padding: 10px 5px 10px 10px;
    font-size: 24px;
    font-weight: 700;
    color:#607D8B;
  }
  .imputeditar{
    font-weight: 700;
    color:#3f3f3f;
    border: 1px solid #FFF;
    border-bottom: 2px solid #607D8B;
    box-sizing: border-box;
    max-width: 100%!important;
  }
  .selecteditar{
    font-weight: 700;
    color:#3f3f3f;
    border: 1px solid #FFF;
    border-bottom: 2px solid #607D8B;
    box-sizing: border-box;
    max-width: 100%!important;
  }
  .imputeditar:focus{
    outline: none !important;
    box-shadow: 0 0 20px #607D8B;
  }
  .selecteditar:focus{
    outline: none !important;
    box-shadow: 0 0 20px #607D8B;
  }
  .exitos {
    border: solid 1px rgb(96, 125, 139);
    box-shadow: 0 0 40px #607D8B;
    font-weight: 200;
    font-size: 17px;
    background-color: #219C8F;
  }

  .error {
    border: solid rgb(247, 78, 78);
    box-shadow: 0 0 40px #F74E4E;
    font-weight: 200;
    font-size: 17px;
  }
  .columnatitulo{
    font-size: 14px;
    font-weight: 700;
    color:#607D8B;
  }
  .bloc{
    display:inline-block;
  }
  .sinborde {
    border: 0px solid rgb(247, 78, 78);
    box-shadow: inset 5em 1em white;
  }
  .mui{
    border: 2px solid  white;
    box-shadow: inset 5em 1em white;
    background-color: #219C8F;
  }
  .react-overlay-loader-spinner:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-top: -30px;
    margin-left: -20px;
    border-radius: 50%;
    border: 3px solid #eee;
    border-top-color: #07d;
    animation: react-overlay-loader-spinner 0.8s linear infinite;
  }
  a#qr-reader__dashboard_section_swaplink {

    display: none !important;
    }
    .titulomodulo{
      margin-top: 9px;
      margin-right: 29px;
      padding-left:30px;
      font-weight:700;
      width: 90px;
      text-decoration: none;
    }
    #logobla:hover{ text-decoration: none;}
    .titulomodulo:hover {
      text-decoration: none;
    }
    .titulomodulo:focus {
      text-decoration: none;
    }
    #btnmodulos{
      border-top-color: #07d;
      color:rgb(124, 5, 5);
      position: relative;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
   
    .Midatatab {
      font-size: 5px; /* Tamaño de fuente de 5px */
      background-color: black; /* Fondo negro */
      color: white; /* Color de fuente blanco */
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Color de fondo oscuro con transparencia */
    z-index: 999; /* Asegúrate de que el overlay esté en la parte superior */
  }
